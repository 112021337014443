import '../styles/index.scss';
import LazyLoad from "vanilla-lazyload";

var myLazyLoad = new LazyLoad();
// After your content has changed...
myLazyLoad.update();

if (process.env.NODE_ENV === 'development') {
  require('../index.html');
  require('../a-propos.html');
  require('../case-study.html');
  require('../case-study-nrj.html');
  require('../case-study-ftv.html');
  require('../case-study-chef.html');

  require('../../enedis/index.html');
  //require('../systeme-solaire.html');
}


console.log('Hey ! ;)');
